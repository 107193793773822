import {Button, Col, Container, FloatingLabel, Form, Row} from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import ReCaptchaV2 from 'react-google-recaptcha';
import axios from 'axios';

import RecaptchaFormAlert from './RecaptchaFormAlert'

const RecaptchaForm = () => {

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [show, setShow] = useState(false);
    const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);

    const recaptchaRef = useRef(null);
    const SITE_KEY = process.env.REACT_APP_SITE_KEY;
    const API_URL = process.env.REACT_APP_API_URL;

    const handleSubmit = async (event) => {
        console.log('handle submit invoked');
        event.preventDefault();
        console.log('event',event);
        console.log('event.target',event.target);
        const formData = new FormData(event.target);
        console.log('formData',formData);
        const formDataObj = JSON.stringify(Object.fromEntries(formData.entries()));
        console.log('formDataObj',formDataObj);
        console.log("JSON.parse",JSON.parse(formDataObj));
        const captchaToken = await recaptchaRef.current.getValue();
        console.log('captchaToken',captchaToken);
        recaptchaRef.current.reset();
        console.log('able to reset recaptchaRef');
        
        //
        const res = await axios.post(
            API_URL,
            {
                formData: formDataObj,
                captchaToken: captchaToken
            }
        ).catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log('error.response.data',error.response.data);
              console.log('error.response.status',error.response.status);
              console.log('error.response.headers',error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log('error.request',error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('error.message', error.message);
            }
            console.log('error.config', error.config);
        });
        console.log('res',res);

        if (res.data.success) { 
            setRecaptchaSuccess(true);
            setButtonDisabled(true);
        } else {
            setRecaptchaSuccess(false);
        }
        res.data ? setShow(true) : setShow(false);
    };

    const handleChange = () => {
        console.log('handleChange invoked');
        setButtonDisabled(false);
    };

    return (
        <Container className="contact-form">
            <Form onSubmit={handleSubmit}>
                <h1 className="contact-form-title">CONTACT INFORIGHT</h1>
                <Row>
                    <Col className="mb-3">
                        <Form.Group as={Row} controlId="name">
                            <FloatingLabel controlId="floatingName" label="&nbsp;&nbsp;Your Name">
                                <Form.Control type="text" placeholder="name" name="name" required/>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group as={Row} controlId="email">
                            <FloatingLabel controlId="floatingEmail" label="&nbsp;&nbsp;Email@yourDomain.com">
                                <Form.Control type="email" placeholder="email" name="email" required/>
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group as={Row} controlId="phone">
                            <FloatingLabel controlId="floatingPhone" label="&nbsp;&nbsp;Phone Number">
                                <Form.Control type="tel" placeholder="phone" name="phone"/>
                            </FloatingLabel>
                        </Form.Group>
                    </Col>

                    <Col className="mb-3">
                        <Form.Group as={Row} controlId="textarea">
                            <FloatingLabel controlId="floatingTextarea" label="&nbsp;&nbsp;Your Message">
                                <Form.Control 
                                    as="textarea" 
                                    placeholder="Leave a message"
                                    style={{height: '9.40rem'}}
                                    name="textarea"
                                    required
                                />
                            </FloatingLabel>
                        </Form.Group>
                        <Button className="contact-form-button" variant="outline-primary" type="submit" disabled={buttonDisabled}>
                            Submit
                        </Button>
                    </Col>
                </Row>
                
                <RecaptchaFormAlert show={show} recaptchaSuccess={recaptchaSuccess} setCloseFunction={() => setShow(false)} />

                <ReCaptchaV2
                    ref={recaptchaRef}
                    sitekey={SITE_KEY}
                    onChange={handleChange}
                />
            </Form>
        </Container>
    );
};
export default RecaptchaForm;