import 'bootstrap/dist/css/bootstrap.min.css';
import {Alert} from 'react-bootstrap';

function RecaptchaFormAlert(props) {
  
    if (props.show) {
      return (
        <Alert variant={props.recaptchaSuccess ? 'success' : 'danger'} onClose={props.setCloseFunction} dismissible>
          {props.recaptchaSuccess ? 'Your Message was sent successfully!' : 'There was a problem, try again later...'}
        </Alert>
      );
    }
    return <></>;
  }
  
  export default RecaptchaFormAlert;