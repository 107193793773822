import 'bootstrap/dist/css/bootstrap.min.css';
import './css/About.css';
import './css/Fonts.css';
import { Card, CardGroup, Container } from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import dataLaptop from './img/data_laptop.jpg';
import lineGraph from './img/line_graph.jpg';
import graphs from './img/graphs.jpg';
import buildings from './img/buildings.jpg';

function About () {
    return(
        <div id="about" className="about-wrapper">
            <Container className="mission-statement">
                <h1 className="mission-statement-title">
                    InfoRight
                </h1>
                <p className="mission-statement-body">
                    InfoRight is <span className="mission-statement-highlight">a data and analytics company </span>
                    relentlessly focused on helping organizations use the right data, the right metrics, 
                    and the right processes to make the best decisions. We work with you to achieve 
                    meaningful results as quickly as possible.
                </p>
            </Container>

            <Container className="expertise-cards">
                <h1 className="expertise-cards-title">
                    Expertise
                </h1>
                <CardGroup>
                    <Card>
                        <Card.Img variant="top" src={dataLaptop} />
                        <Card.Body>
                            <Card.Title className="card-title">Data Ingestion &amp; Organization</Card.Title>
                            <Card.Text className="card-text">
                                Our data experience includes data sourcing, ingestion, transformation, ETL, data modeling, 
                                big data processing, automation, data lineage and catalogs, versioning, validation, 
                                auditing, and security. We work with data in most any format: flat files, SQL, NoSQL, 
                                proprietary file formats, etc.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={lineGraph} />
                        <Card.Body>
                            <Card.Title className="card-title">Metrics, Analysis, &amp; Prediction </Card.Title>
                            <Card.Text className="card-text">
                                Let us help you with metrics selection and calculation, data analysis, machine learning, 
                                predictive and prescriptive analysis, control charts, and several advanced statistical 
                                methods and rigor.  Or, we will simply build and maintain the comprehensive data mart 
                                for your self-service team.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Img variant="top" src={graphs} />
                        <Card.Body>
                            <Card.Title className="card-title">Process Design and Improvement</Card.Title>
                            <Card.Text className="card-text">
                                Our experience and credentials include Six Sigma Master Black Belt certification, 
                                process design certification, process improvement, financial cost reduction, 
                                capacity increases, and overall process strategy. Additionally, we are Black Belt 
                                certified in Lean and in design thinking methods.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Container>

            <Container fluid className="testimony">
                <Parallax
                    blur={{ min: -15, max: 15 }}
                    bgImage={buildings}
                    bgImageAlt="testimony-bg"
                    strength={-200}
                >
                    
                    <Container className="testimony-main">
                        
                        <h1 className="testimony-text">
                            <span className="quotations">" </span>
                            InfoRight has been a lifesaver for us at Superconductive! We needed to scale up 
                            our data mapping projects quickly, but we needed people with deep healthcare 
                            domain knowledge and the ability to fit into our custom data engineering processes 
                            quickly. InfoRight has become a central part of our team as we've needed to scale our 
                            capacity. We couldn't have survived without InfoRight's key support!
                            <span className="quotations"> "</span>
                        </h1>
                        
                        <h2 className="testimony-author">
                            - Ben Castleton, Superconductive Health, Inc. -
                        </h2>
                    </Container>
                    
                </Parallax>
            </Container>
        </div>
    );
};

export default About;