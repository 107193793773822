import './App.css';
import About from './Components/About';
import Contact from './Components/Contact';
import Home from './Components/Home';
import NavBar from './Components/Navbar';
import Services from './Components/Services';
import Team from './Components/Team';

function App() {
  return (
    <>
      <NavBar />
      <Home />
      <About />
      <Team />
      <Services />
      <Contact />
    </>
    
  );
}

export default App;
