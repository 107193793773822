import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Team.css';
import './css/Fonts.css';
import { Button, Card, CardGroup, Container, Carousel, Row } from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import chris from './img/chris.jpg';
import alty from './img/alty.jpg';
import patrick from './img/patrick.jpg';
import mike from './img/mike.png';
import tanner from './img/tanner.jpg';
import abdias from './img/abdias.jpg';
import serdar from './img/serdar.jpg';
import chinmay from './img/chinmay.jpg';
import nashmeyah from './img/nashmeyah.png';
import atif from './img/atif.jpg';
import stephanie from './img/stephanie.jpg';
import andrew from './img/andrew.png';

import superconductive from './img/clientSuperconductive.png';
import barrick from './img/ClientBarrick.png';
import IPFS from './img/ClientIPFS.png';
import aluvii from './img/ClientAluvii.png';
import kinetix from './img/ClientTkg.jpg';
import umbric from './img/ClientUmbric.jpg'
import ensoRings from './img/ClientEnsoRings.svg';
import USE from './img/ClientUSE.jpg'
import code from './img/background_code.jpg';

function Team () {
    return(
        <div id="team-mission">
            <Container className="team-statement">
                <h1 className="team-statement-title">
                    Our <span className="team-statement-title-highlight">Team</span>
                </h1>
                <p className="team-statement-body">
                    Our team has worked in business intelligence, statistical and predictive analysis, 
                    process improvement, product management, and project management. We have worked in 
                    various industries including healthcare, insurance, financial services, and precious 
                    metals &amp; mining.
                </p>
            </Container>
            <Container className="team-cards" id="team">
            <Row>
                    <CardGroup>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={nashmeyah} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Nashmeyah Al-Rekabi</Card.Title>
                                <Card.Text className="team-card-text">Software Engineer</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={atif} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Atif Farrukh</Card.Title>
                                <Card.Text className="team-card-text">DevOps Engineer</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={alty} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Alty Kosherbek</Card.Title>
                                <Card.Text className="team-card-text">Business Development Manager</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={mike} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Mike Hawley</Card.Title>
                                <Card.Text className="team-card-text">Data Architecture Director</Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Row>
                <Row>
                    <CardGroup>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={stephanie} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Stephanie Cruz</Card.Title>
                                <Card.Text className="team-card-text">Sales & Marketing Manager</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={patrick} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Patrick Igwe</Card.Title>
                                <Card.Text className="team-card-text">Software Engineer</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={chris} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Chris Rains</Card.Title>
                                <Card.Text className="team-card-text">CEO, Founder</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={abdias} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Abdias Baldiviezo</Card.Title>
                                <Card.Text className="team-card-text">Analytics Engineer</Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Row>
                <Row>
                    <CardGroup>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={tanner} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Tanner Dietrich</Card.Title>
                                <Card.Text className="team-card-text">Analytics Director</Card.Text>
                            </Card.Body>
                        </Card>
                        
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={serdar} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Serdar Lyon</Card.Title>
                                <Card.Text className="team-card-text">Software Engineer</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={andrew} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Andrew Young</Card.Title>
                                <Card.Text className="team-card-text">Data Analyst</Card.Text>
                            </Card.Body>
                        </Card>
                        <Card bg="light" className="p-3 m-3">
                            <Card.Img variant="top" src={chinmay} />
                            <Card.Body>
                                <Card.Title className="team-card-title">Chinmay Gandhi</Card.Title>
                                <Card.Text className="team-card-text">Data Engineer</Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </Row>
                    
                    
            </Container>
            <Container fluid className="clients">
                <h1 className="clients-title">We have worked with these companies </h1>
                <Carousel className="clients-carousel">
                    <Carousel.Item>
                        <img
                        src={superconductive}
                        alt="Superconductive"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={barrick}
                        alt="Barrick"
                        height="80px"
                        width="150px"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={IPFS}
                        alt="IPFS"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={aluvii}
                        alt="Aluvii"
                        height="80px"
                        width="150px"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={kinetix}
                        alt="The Kinetix Group"
                        height="100px"
                        width="100px"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={umbric}
                        alt="Umbric"
                        height="100px"
                        width="100px"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={ensoRings}
                        alt="Enso Rings"
                        height="100px"
                        width="200px"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                        src={USE}
                        alt="USE Credit Union"
                        height="100px"
                        width="100px"
                        />
                    </Carousel.Item>
                </Carousel>
            </Container>
            <Container fluid className="testimony-parallax">
                <Parallax
                    blur={{ min: -15, max: 15 }}
                    bgImage={code}
                    bgImageAlt="code-bg"
                    strength={-200}
                >
                    
                    <Container className="testimony-parallax-main">
                        
                        <h1 className="testimony-parallax-text">
                            We were in need of a Sr. Level Developer / Data Expert.  After much searching, 
                            we found InfoRight. They came in and were able to augment our staff for several 
                            months.  InfoRight was a pleasure to work with.  Very knowledgeable, professional 
                            and capable of doing anything we put in front of their team. After working with 
                            them for several months we saw the value of having them train some of our other 
                            developers on some advanced development.  Because of them, our efficiency and knowledge 
                            as a team greatly improved. We love the team at InfoRight and will be using them in the 
                            future. I will not hesitate to call them in the future.
                        </h1>
                        <br/>
                        <h2 className="testimony-parallax-author">
                            Todd Moynier <br/>
                            Anderson Solutions Group
                        </h2>
                    </Container>
                </Parallax>
            </Container>
            <Container fluid className="red-contact-us">
                <h2 className="red-contact-us-title">
                    Contact Us!
                </h2>
                <Button size="lg" variant="outline-light" href="#contact">
                    Click Here
                </Button>
            </Container>
        </div>
    );
};

export default Team;