import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Home.css';
import './css/Fonts.css';
import image from './img/backgroundMountainDarkCropped.jpg';
import logo from './img/inforight_logo_transparent.png';
import { Parallax } from 'react-parallax';
import { Button, Container } from 'react-bootstrap';

function Home () {
    return(
        <div id="home" className="home-wrapper">
            <Parallax
                blur={{ min: -15, max: 15 }}
                bgImage={image}
                bgImageAlt="home-bg"
                strength={-200}
            >
                <Container className="home-logo">
                    <img
                        alt="inforight logo"
                        src={logo}
                        width="250"
                        height="250"
                        className="d-inline-block"
                    />
                </Container>
                
                <Container fluid className="home-banner">
                    <h1>The right data.</h1>
                    <h1>The right metrics.</h1>
                    <h1>The best decisions. </h1>
                </Container>

                <Container className="home-button">
                    <Button variant="outline-light" href="#about">
                        Read More
                    </Button>
                </Container>
                
            </Parallax>
        </div>
        
    );
};

export default Home;