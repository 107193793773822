import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Contact.css';
import './css/Fonts.css';
import {Container} from 'react-bootstrap';
import RecaptchaForm from './RecaptchaForm';


function Contact() {
      
    return(

        <div id="contact">
            <Container className="contact-statement">
                <h1 className="contact-statement-title">
                    Contact <span className="contact-statement-title-highlight">Us!</span>
                </h1>
                <p className="contact-statement-body">
                    <span className="contact-statement-highlight">Reach out </span>
                    through the form below or
                    <span className="contact-statement-highlight"> visit us. </span>
                </p>
            </Container>

            <RecaptchaForm />

            <Container fluid className="map">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3030.460993686863!2d-111.90488648506934!3d40.57557875388792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8752879ad980ab05%3A0x22f0b98014d91b3e!2sInfoRight%2C%20Inc!5e0!3m2!1sen!2sus!4v1645820268082!5m2!1sen!2sus" 
                    title="inforight map"
                    width="100%" 
                    height="500px" 
                    style={{border: '0'}} 
                    allowfullscreen="" 
                    loading="lazy">
                </iframe>
            </Container>

            <Container fluid className="red-inforight">
                <h2 className="red-inforight-title">
                    InfoRight
                </h2>
                <p className="red-inforight-copyright">© 2022 InfoRight</p>
            </Container>
        </div>
    );
};

export default Contact;