import 'bootstrap/dist/css/bootstrap.min.css';
import './css/Services.css';
import './css/Fonts.css';
import { Card, CardGroup, Container, ListGroup } from 'react-bootstrap';
import uslights from './img/usLights.jpg';
import geoanalysis from './img/geoanalysis.jpg';
import mesh from './img/mesh.jpg';

function Services() {
    return(
        <div id="services">
            <Container className="services-statement">
                <h1 className="services-statement-title">
                    Our <span className="services-statement-title-highlight">Services</span>
                </h1>
                <p className="services-statement-body">
                    InfoRight offers consulting and managed services arrangements. We help you get 
                    the right data, the right metrics, and the right processes in place to make 
                    the best decisions. We work with you to achieve meaningful results as quickly as possible. 
                </p>
            </Container>
            <Container className="services-cards">
                <CardGroup>
                    <Card bg="light" className="p-3 m-3 services-card">
                        <Card.Img variant="top" src={uslights} />
                        <Card.Body>
                            <Card.Title className="services-card-title">
                                Data Sourcing &amp; Organization 
                                <br/>
                                <span className="services-card-subtitle">Sourcing, ingestion, ETL.</span>
                            </Card.Title>
                            <Card.Text className="services-card-text">
                                
                                <h5>We'll help with: </h5>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>Data (and big data) Ingestion</ListGroup.Item>
                                    <ListGroup.Item>Data lineage, catalogs, &amp; versioning</ListGroup.Item>
                                    <ListGroup.Item>Validation, security, &amp; auditing</ListGroup.Item>
                                    <ListGroup.Item>SQL, NoSQL, flat files, etc.</ListGroup.Item>
                                </ListGroup>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card bg="light" className="p-3 m-3 services-card">
                        <Card.Img variant="top" src={geoanalysis} />
                        <Card.Body>
                            <Card.Title className="services-card-title">
                                Metrics and Analysis 
                                <br/>
                                <span className="services-card-subtitle">Metrics, analysis, and statistics.</span>
                            </Card.Title>
                            <Card.Text className="services-card-text">
                                
                                <h5>We'll help with: </h5>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>Metrics selection &amp; calculation</ListGroup.Item>
                                    <ListGroup.Item>Data analysis &amp; recommendation</ListGroup.Item>
                                    <ListGroup.Item>Machine learning &amp; prediction</ListGroup.Item>
                                    <ListGroup.Item>Fully supporting your team</ListGroup.Item>
                                </ListGroup>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card bg="light" className="p-3 m-3 services-card">
                        <Card.Img variant="top" src={mesh} />
                        <Card.Body>
                            <Card.Title className="services-card-title">
                                Process &amp; Product Design
                                <br/>
                                <span className="services-card-subtitle">Certified, experienced help.</span>
                            </Card.Title>
                            <Card.Text className="services-card-text">
                                
                                <h5>We'll help with: </h5>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>Lean and Six Sigma</ListGroup.Item>
                                    <ListGroup.Item>Process strategy and design</ListGroup.Item>
                                    <ListGroup.Item>Financial impacts</ListGroup.Item>
                                    <ListGroup.Item>Product design</ListGroup.Item>
                                </ListGroup>
                            </Card.Text>
                        </Card.Body>
                    </Card>    
                </CardGroup>
            </Container>
        </div>
    );
};

export default Services;