import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import './css/Navbar.css';
import './css/Fonts.css';
import logo from './img/inforight_logo_transparent.png';
function NavBar () {
    return (
        <Navbar className="general-navbar-wrapper" fixed="top" variant="dark">
            <Container className="general-navbar-container-wrapper">
                <Container className="brand-wrapper">
                    <Navbar.Brand href="#home">
                        <img
                            alt=""
                            src={logo}
                            width="30"
                            height="30"
                            className="d-inline-block"
                        />
                        {" "}
                        InfoRight
                    </Navbar.Brand>
                </Container>
                
                <Nav className="justify-content-right nav-links-text">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#about">About Us</Nav.Link>
                    <Nav.Link href="#team">Team</Nav.Link>
                    <Nav.Link href="#services">Services</Nav.Link>
                    <Nav.Link href="#contact">Contact Us</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
};

export default NavBar;